import React from 'react'

const MarineCS = () => {
  return (
    <div className='mt-[10vh]'>
      Marine Case study
    </div>
  )
}

export default MarineCS
